html {
  scroll-behavior: smooth;
}

.hub section:first-child {
  p,h1,h2,h3,h4,h5 {
    color: white;
    span {
      &.highlight-yellow {
        color: #FFC107; 
      }
    }
  }
  .description {
    h4 {
      font-size: 250%;
    }
    h5 {
      font-size: 1.75rem;
    }
    img {
      width: 50%;
    }
    .btn {
      color:black;
      background-color: #FFC107;
      border-radius: 1rem;
      box-shadow: 0 5px 10px rgba(50, 50, 93, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

.hub section:nth-child(2) {
  color: black;
  p {
    font-size:1.5rem
  }
}

.hub section:nth-child(3) {
  background-color: #e9e9e9;
  .testimony-box {
    background-color: white;
    border-color: gray;
    border: 2px solid;
    border-radius: 17.5px;
  }
  .btn {
    color:black;
    background-color: #FFC107;
    border-radius: 1rem;
    box-shadow: 0 5px 10px rgba(50, 50, 93, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
  }
}


.hub section:nth-child(4) {
  background-color: rgb(25, 7, 53);

  .container {
    .legend {
      text-decoration: underline;
      font-style: italic;
      color: white;
      font-weight: bolder;
    }
  }
}

.hub section:nth-child(5) {
  background-color: #7c13a4;

  .col-lg-6:nth-of-type(1) .box {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .col-lg-6:nth-of-type(2) .box {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .box {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 4px solid white;
    border-radius: 15px;
    padding-top: 1rem;
    padding-bottom: 2px;
    ul {
      flex: 1; 
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      list-style: none;
      
      li {
        padding-left: 25px;
        text-indent: -25px;
        color: white;
        font-size: 20px;
        padding: 4px 0; 

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          background-size: cover;
        }
    
      }
      &.list-no li::before {
        content: "✖";
        color: red;
        font-size: 24px;
        padding-right: 5px;
      }

      &.list-yes li::before {
        content: "✔";
        color: green;
        font-size: 24px;
      }
    }


    h3 {
      color: white;
      font-weight: bolder;
    }
  }
  
  .btn {
    color:black;
    background-color: #FFC107;
    padding-left: 25%;
    padding-right: 25%;
    border-radius: 1rem;
    box-shadow: 0 5px 10px rgba(50, 50, 93, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
  }
}

.hub section:nth-child(6) {
  background-color: white;
  .text-container {
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-size:1.2rem;
    }
  }
}

.hub section:nth-child(7) {
  background-color: #7c13a4;
  p,h1,h2,h3,h4,h5,li {
    color:white;
  }

  .box {
    border: 2px solid white;
    border-radius: 1rem;
    background-color: rgba(0,0,0,0.25);
    padding: 25px;

    .btn {
      color:black;
      background-color: #FFC107;
      border-radius: 1rem;
      box-shadow: 0 5px 10px rgba(50, 50, 93, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
      &.green {
        font-size: 1.5rem;
        background-color: $green;
      }
      &.red {
        font-size: 1.75rem;
        background-color: $red;
      }
    }

    ul {
      list-style: none;
      li {
        font-size: 1.15rem;
        &:before {
          content: "▶ ";
          color: $yellow;
        }
      }
    }

    .yellow-bk {
      background-color: #FFC107;
      border-radius: 0.5rem;
      p {
        color: black;
        font-size: 1.1rem
      }
    } 
  }
}

.hub section:nth-child(8) {
  background-color: white;
  padding-bottom: 0;
}

.hub section:nth-child(9) {
  background-color: black;
  h2,h4 {
    color:white;
    line-height: 3rem;
  }
}

.hub section:nth-child(10) {
  .faq-container {
    margin: auto;
    width: 100%;
  }
  
  .faq-item {
    border: 1px solid #ddd;
    color:black;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  
    .faq-question {
      color:black;
      width: 100%;
      text-align: left;
      font-weight: bold;
      background: #f8f9fa;
      border: none;
      padding: 15px;
      cursor: pointer;
      transition: background 0.3s;
  
      &:hover {
        background: #e9ecef;
      }
    }
  
    .faq-answer {
      background: white;
      padding: 15px;
      border-top: 1px solid #ddd;
    }
  }
}

.hub section:nth-child(11) {
  background-color: #FFC107;
  h1 {
    color: black;
    strong {
      color: #7c13a4;
    }
  }
  h4 {
    color: black;
  }
  .btn {
    color:white;
    background-color: #FFC107;
    border-radius: 1rem;
    box-shadow: 0 5px 10px rgba(50, 50, 93, 0.05), 0 5px 10px rgba(0, 0, 0, 0.1);
    font-size: 1.75rem;
    background-color: $red;
  }
}

@media (max-width: 480px) {
  .hub section:first-child {
    .description {
      h4 {
        font-size: 25px;
      }
      h5 {
        font-size: 20px;
      }
    }
  }
  
  .hub section:nth-child(2) {
    text-align: center;
    p {
      font-size:1.5rem
    }

    h3 {
      font-size: 24px;
    }

    h2,p {
      font-size: 20px;  
    }
  }
  
  .hub section:nth-child(3) {
    .testimony-box{
      margin: 25px;
      margin-bottom: -15px;
    }
  }
  
  .hub section:nth-child(4) {
  }
  
  .hub section:nth-child(5) {
  
    .col-lg-6:nth-of-type(1) {
      padding-bottom: 25px;
    }
    .box {
      ul {
        li {
          font-size: 20px;
          line-height: normal;
        }
      }
  
  
      h3 {
        font-size: 28px;
      }
    }
    
    padding-bottom: 20px;
  }
  
  .hub section:nth-child(6) {
    background-color: white;
    .text-container {
      height: 100%;
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        font-size:1.2rem;
      }
    }
  }
  
  .hub section:nth-child(7) {
    .box {
      .row {
        flex-flow: column-reverse
      }
      ul {
        li {
          font-size: 1.15rem;
        }
      }
  
      .yellow-bk {
        p {
          font-size: 1.1rem
        }
      } 
    }

    .container .row:nth-child(2) {
      padding: 15px;
    }
  }
  
  .hub section:nth-child(8) {
    padding-bottom: 4rem;
    .col-lg-8 {
      display: none;
    }
  }
  
  .hub section:nth-child(10) {
    .faq-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  .hub section:nth-child(11) {
    h1 {
      font-size: 25px;
    }
    h4 {
      font-size: 20px;
    }
    .btn {
      font-size: 1.25rem;
    }
  }
}